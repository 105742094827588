<template>
  <div :id="chartName" class="chart-wrapper" :style="esize"></div>
</template>
<script>
import { chartText } from "@/libs/mixins/chartSubText.js";
import { getExcel } from "@/libs/mixins/GetExcel.js";
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3",
];
export default {
  name: "riskSpread",
  data() {
    return {
      chartName: this.$options.name,
      title: "风险点专业/级别分布图",
      chartData: [],
      majorList:[],
      degreeList:[],
      chColor: "#c23531",
    };
  },
  props: {
    choosedOptions: {
      type: Object,
      default() {
        return {
          selectedList: [],
          keyword: "",
        };
      },
    },
    esize: {
      type: Object,
      default() {
        return { height: "700px", width: "1000px" };
      },
    },
    drawAgain: {
      type: Number,
      default: 1,
    },
  },
  mixins: [chartText,getExcel],
  mounted() {
    let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];
  },
  watch: {
    drawAgain: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.get_data();
        }
      },
    },
  },
  computed: {
    // ctdata() {
    //   let temp = this.chartData;
    //   temp.sort((a, b) => {
    //     return a.value - b.value;
    //   });
    //   return temp;
    // },
    // majorList: function () {
    //   let tmp = [];
    //   for (let i of this.chartData) {
    //     if (tmp.indexOf(i[0]) === -1) {
    //       tmp.push(i[0]);
    //     }
    //   }
    //   return tmp;
    // },
    // degreeList: function () {
    //   let tmp = [];
    //   for (let i of this.chartData) {
    //     if (tmp.indexOf(i[1]) === -1) {
    //       tmp.push(i[1]);
    //     }
    //   }
    //   return tmp;
    // },
    // askData: function () {
    //   let tmp = [];
    //   for (let i of this.lineList) {
    //     tmp.push([2016, parseInt(i), 0]);
    //   }
    //   for (let i of this.totalData) {
    //     tmp.push([
    //       parseInt(i.year),
    //       parseInt(i.line_name),
    //       i.delayAmount[this.ivchoice],
    //     ]);
    //   }
    //   for (let i of this.lineList) {
    //     tmp.push([Math.max(...this.yearList) + 1, parseInt(i), 0]);
    //   }
    //   for (let i of this.lineList) {
    //     tmp.push([Math.max(...this.yearList) + 2, parseInt(i), 0]);
    //   }
    //   return tmp;
    // },
    originData:function(){
      let res = [];
      let tmp = [];
      for(let ele of this.chartData){
        tmp = [];
        tmp[0] = this.majorList[ele[0]];
        tmp[1] = this.degreeList[ele[1]];
        tmp[2] = ele[2];
        res.push(tmp);
      }
      return res;
    }
  },
  methods: {
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/chart", {
          chartName: this.chartName,
          choosedOptions: this.choosedOptions,
          userToken: userToken,
        })
        .then((response) => {
          this.chartData = response.data.record;
          this.majorList = response.data.majorList;
          this.degreeList = response.data.degreeList;
          this.drawIt();
        });
    },
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById(this.chartName));
      // 绘制图表
      // let dataR = [
      //   this.chartData,
      // ]; 
      /*[
        [
          [2016, 2, 0],
 [2016, 3, 0],
[2016, 4, 0],
[2017, 2, 1],
 [2017, 3, 4],
[2017, 4, 1],
[2018, 2, 0],
[2018, 3, 0],
[2018, 4, 0],
[2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
[2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
 [2019, 2, 0],
[2020, 3, 0],
[2021, 4, 0],
        ]
      ];*/
      let option = {
        backgroundColor: "#f7f7f7",
        title: {
          top: "10%",
          left: "center",
          text: "风险点数量专业/级别分布图",
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            myToolsExcel:{
              paddingTop:'5px',
              show:true,
              top:'20px',
              title: '保存数据到EXCEL',
              icon:'image:///img/excel.png',
              onclick:function(){
                vm.excelGo(vm.originData,['专业','严重度等级','数量']);
                }
            },
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
            
          },
        },
        legend: {
          top: "5%",
          right: "5%",
          data: [],
        },
        grid: {
          x: "18%",
          y: "25%",
          x2: "18%",
          y2: "18%",
          borderWidth: 10,
        },
        xAxis: {
          data:vm.majorList,
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            alignWithLabel: true,
            show:true,
            lineStyle: {
            color: '#aaa',
            type: 'dashed',
            width: "1"
            }
          },
          // scale: true,
        },
        yAxis: {
          data:vm.degreeList,
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            alignWithLabel: true,
            show:true,
            lineStyle: {
            color: '#aaa',
            type: 'dashed',
            width: "1"
            }
          },
          // axisLabel: {
          //   formatter: function (s) {
          //     if (s === "0" || s === 0) {
          //       return "";
          //     } else {
          //       return s + "";
          //     }
          //   },
          // },
        },
        series: [
          {
            data: vm.chartData,
            type: "scatter",
            symbolSize: function (data) {
              return data[2] * 2;
            },
            label: {
              emphasis: {
                show: true,
                formatter: function (param) {
                  return param.data[2] + "";
                },
                position: "top",
              },
            },
            itemStyle: {
              color: vm.chColor,
              opacity: 0.8,
              shadowBlur: 1,
              shadowColor: "#e6e6e6",
              shadowOffsetX: 2,
              shadowOffsetY: 2
            }
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  border: 1px solid #eeeded;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
}
</style>