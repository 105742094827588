<template>
  <div
    id="idg"
    class="chart-wrapper"
    :style="{ height: '230px', width: '100%' }"
  ></div>
</template>
<script>
/* function CounterAmount(oridata, key) {
        let arda = {};
        for (let ele of oridata) {
            if (ele[key] in arda) {
                arda[ele[key]]++;

            } else {
                arda[ele[key]] = 1;

            }
        }
        return arda;
    }
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}

let BarColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3"
];*/

export default {
  name: "chartTemp3",
  props: [
    "ivchoice",
    "totalData",
    "selected_list",
    "keyword",
    "title_text",
    "sub_title_text",
    "chart_draw_again",
    "esize",
    "major",
  ],
  data() {
    return {
      /*ids: this.ivchoice,
      chartData: [],
      chartKey: this.ivchoice,
      chColor: "#c23531"*/
    };
  },
  mounted() {
    /*let n = rnd(0, BarColor.length - 1);
    this.chColor = BarColor[n];*/
  },
  watch: {
    chart_draw_again: function (val) {
      if (val > 0) {
        this.drawIt();
      }
    },
  },
  computed: {
    lineList: function () {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.line_name) === -1) {
          tmp.push(i.line_name);
        }
      }
      tmp.sort();
      return tmp;
    },
    yearList: function () {
      let tmp = [];
      for (let i of this.totalData) {
        if (tmp.indexOf(i.year) === -1) {
          tmp.push(i.year);
        }
      }
      tmp.sort();
      tmp.push(tmp[tmp.length - 1] + 1);
      tmp.push(tmp[tmp.length - 1] + 1);
      return tmp;
    },
    opt: function () {
      let opt = [];
      let ser, majorData, tmpd, totalinser, sum;
      for (let i of this.yearList) {
        ser = [];
        totalinser = [];

        for (let j of this.lineList) {
          majorData = [];
          sum = 0;
          for (let m in this.major) {
            tmpd = this.totalData.find(
              (val) => val.year === i && val.line_name === j
            );
            majorData.push({
              name: this.major[m],
              value: tmpd ? tmpd["delayAmount"][m] : 0,
            });
            sum += tmpd ? tmpd["delayAmount"][m] : 0;
          }
          ser.push({ data: majorData });
          totalinser.push({ name: j, value: sum });
        }
        ser.push(totalinser);
        opt.push({ series: ser, title: { text: i + "年" } });
      }
      return opt;
    },
  },
  methods: {
    /*get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/ask_for_chartdata", {
          theme: this.ivchoice,
          data_name: this.data_name,
          selected_list: this.selected_list,
          keyword: this.keyword,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },*/
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById("idg"));
      // 绘制图表

      /*var dataMap = {};
      function dataFormatter(obj) {
        var pList = [
          "车辆",
          "工务设施",
          "供电",
          "信号",
          "站台门",
          "调度不当",
          "乘务操作",
          "站务操作",
          "乘客",
          "其他设备",
          "其他"
        ];
        var temp;
        for (var year = 2017; year <= 2021; year++) {
          var max = 0;
          var sum = 0;
          temp = obj[year];
          for (var i = 0, l = temp.length; i < l; i++) {
            max = Math.max(max, temp[i]);
            sum += temp[i];
            obj[year][i] = {
              name: pList[i],
              value: temp[i]
            };
          }
          obj[year + "max"] = Math.floor(max / 100) * 100;
          obj[year + "sum"] = sum;
        }
        return obj;
      }

      dataMap.dataL2 = dataFormatter({
        2021: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2020: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2019: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2018: [5, 1, 0, 16, 3, 0, 1, 0, 0, 0, 2],
        2017: [2, 0, 0, 9, 1, 2, 1, 0, 1, 0, 3]
      });

      dataMap.dataL3 = dataFormatter({
        2021: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2020: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2019: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2018: [0, 0, 0, 10, 2, 0, 0, 0, 0, 0, 2],
        2017: [0, 2, 0, 2, 0, 0, 0, 0, 4, 0, 0]
      });

      dataMap.dataL4 = dataFormatter({
        2021: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2020: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2019: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        2018: [2, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
        2017: [3, 1, 0, 0, 0, 0, 0, 0, 1, 0, 1]
      });*/
      let dn = new Date();
      let dataTimeLine = [];
      for (let i of vm.yearList) {
        if (i <= dn.getFullYear()) {
          dataTimeLine.push(i + "年");
        } else {
          dataTimeLine.push({
            value: i + "年",
            tooltip: {
              formatter: "{b} 未来",
            },
            symbol: "diamond",
            symbolSize: 16,
          });
        }
      }

      let colorList = [];
      let seriesOut = [];
      vm.lineList.sort((a, b) => parseInt(a) - parseInt(b));
      for (let i of vm.lineList) {
        colorList.push(vm.totalData.find((val) => val.line_name === i)["line_color"]);
        seriesOut.push({ name: i, type: "bar" });
      }
      seriesOut.push({
        name: "不正点列次占比",
        type: "pie",
        center: ["75%", "35%"],
        radius: ["15%", "28%"],
        z: 100,
      });

      let majorList = [];
      for (let i in vm.major) {
        majorList.push(vm.major[i]);
      }

      let option = {
        baseOption: {
          timeline: {
            show:false,
            // y: 0,
            axisType: "category",
            // realtime: false,
            // loop: false,
            autoPlay: true,
            // currentIndex: 2,
            playInterval: 1000,
            // controlStyle: {
            //   
            //     position: 'left'
            // },
            data: dataTimeLine /*[
              "2017年",
              "2018年",
              "2019年",
              {
                value: "2020年",
                tooltip: {
                  formatter: "{b} 未来"
                },
                symbol: "diamond",
                symbolSize: 16
              },
              {
                value: "2021年",
                tooltip: {
                  formatter: function(params) {
                    return params.name + " 未来";
                  }
                },
                symbol: "diamond",
                symbolSize: 16
              }
            ],*/,
            label: {
              formatter: function (s) {
                return s;
              },
            },
          },
          color: colorList, //["#EE7A33", "#E40082", "#00A14E"]
          title: {
            top: "5%",
            left: "5%",
            subtext: "",
            textStyle: {
              color: "#aaa",
              fontSize: "12px",
            },
          },
          toolbox: {
            show: false,
            orient: "vertical",
            left: "right",
            top: "center",
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              restore: { show: true },
              saveAsImage: { show: true },
            },
          },
          legend: {
            width: 300,
            type: "scroll",
            top: "1%",
            right: "1%",
            x: "right",
            textStyle: {
              color: "#aaa",
            },
            data: vm.lineList, //["2号线", "3号线", "4号线"]
            selected: {},
          },
          calculable: true,
          grid: {
            top: "30%",
            bottom: "35%",
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value.replace("\n", "");
                  },
                },
              },
            },
          },
          xAxis: [
            {
              type: "category",
              data: majorList, // ["车辆","工务设施","供电","信号","站台门","调度不当","乘务操作","站务操作","乘客","其他设备","其他"]
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  type: "solid",
                  color: "#2258c3", //左边线的颜色
                  opacity: 0.3,
                },
              },
              axisLabel: {
                interval: 0,
                show: true,
                textStyle: {
                  color: "#aaa",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "列次（次）",
              max: 20,
              splitLine: {
                lineStyle: {
                  color: "#4784e8",
                  opacity: 0.1,
                },
              },
              axisLine: {
                lineStyle: {
                  type: "solid",
                  color: "#2258c3", //左边线的颜色
                  opacity: 0.3,
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#aaa",
                },
              },
            },
          ],
          series: seriesOut /*[
            { name: "2号线", type: "bar" },
            { name: "3号线", type: "bar" },
            { name: "4号线", type: "bar" },
            {
              name: "不正点列次占比",
              type: "pie",
              center: ["75%", "35%"],
              radius: ["15%", "28%"],
              z: 100
            }
          ]*/,
        },
        options:
          vm.opt /*[
          {
            title: { text: "2017列车不正点列次" },
            series: [
              { data: dataMap.dataL2["2017"] },
              { data: dataMap.dataL3["2017"] },
              { data: dataMap.dataL4["2017"] },
              {
                data: [
                  { name: "2号线", value: dataMap.dataL2["2017sum"] },
                  { name: "3号线", value: dataMap.dataL3["2017sum"] },
                  { name: "4号线", value: dataMap.dataL4["2017sum"] }
                ]
              }
            ]
          },
          {
            title: { text: "2018列车不正点列次" },
            series: [
              { data: dataMap.dataL2["2018"] },
              { data: dataMap.dataL3["2018"] },
              { data: dataMap.dataL4["2018"] },
              {
                data: [
                  { name: "2号线", value: dataMap.dataL2["2018sum"] },
                  { name: "3号线", value: dataMap.dataL3["2018sum"] },
                  { name: "4号线", value: dataMap.dataL4["2018sum"] }
                ]
              }
            ]
          },
          {
            title: { text: "2019列车不正点列次" },
            series: [
              { data: dataMap.dataL2["2019"] },
              { data: dataMap.dataL3["2019"] },
              { data: dataMap.dataL4["2019"] },
              {
                data: [
                  { name: "2号线", value: dataMap.dataL2["2019sum"] },
                  { name: "3号线", value: dataMap.dataL3["2019sum"] },
                  { name: "4号线", value: dataMap.dataL4["2019sum"] }
                ]
              }
            ]
          },
          {
            title: { text: "2020列车不正点列次" },
            series: [
              { data: dataMap.dataL2["2020"] },
              { data: dataMap.dataL3["2020"] },
              { data: dataMap.dataL4["2020"] },
              {
                data: [
                  { name: "2号线", value: dataMap.dataL2["2020sum"] },
                  { name: "3号线", value: dataMap.dataL3["2020sum"] },
                  { name: "4号线", value: dataMap.dataL4["2020sum"] }
                ]
              }
            ]
          },
          {
            title: { text: "2021列车不正点列次" },
            series: [
              { data: dataMap.dataL2["2021"] },
              { data: dataMap.dataL3["2021"] },
              { data: dataMap.dataL4["2021"] },
              {
                data: [
                  { name: "2号线", value: dataMap.dataL2["2021sum"] },
                  { name: "3号线", value: dataMap.dataL3["2021sum"] },
                  { name: "4号线", value: dataMap.dataL4["2021sum"] }
                ]
              }
            ]
          }
        ]*/,
      };
      myChart.setOption(
        option
        /*{
                    backgroundColor: '#f7f7f7',
                    
                    title: {
                        text: vm.title_text,
                        subtext: vm.sub_title_text,
                        left: 'center',
                        top: "12%",
                        textStyle: {
                            color: '#888',
                            verticalAlign: 'top'
                        }
                    },
                    tooltip: {
                        trigger: 'item',
                    },
                    grid: {
                        x: "25%",
                        y: "25%",
                        x2: "25%",
                        y2: "25%",
                        borderWidth: 10
                    },

                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                            mark: {
                                show: true
                            },
                            dataView: {
                                show: true,
                                readOnly: false
                            },
                            restore: {
                                show: true
                            },
                            saveAsImage: {
                                show: true
                            }
                        }
                    },
                    dataset: {
                        source: vm.ctdata
                    },
                    xAxis: {},
                    yAxis: {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel:{
                            rotate:30,
                        },
                    },
                    series: [{
                        name: '数量',
                        type: 'bar',
                        barWidth: "40%",
                        encode: {
                            x: 1,
                            y: 2
                        },
                        label:{
                            show:true,
                            rotate:15,
                        },
                        itemStyle: {
                            color:vm.chColor,
                            opacity: 0.8,
                            shadowBlur:1,
                            shadowColor:"#e6e6e6",
                            shadowOffsetX:2,
                            shadowOffsetY:2
                        }
                    }, ],
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                }*/
      );
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });**/
      myChart.on("legendselectchanged", function (value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartKey,
        });
      });
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
}
</style>