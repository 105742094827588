<template>
  <div :id="chartName" class="chart-wrapper" :style="esize"></div>
</template>
<script>
import { CounterAmount } from "@/libs/lib.js";
import {chartText} from "@/libs/mixins/chartSubText.js";

export default {
  name: "charts",
  data() {
    return {
      chartName: "degreeLevel",
      title:"",
      chartData: []
    };
  },
  props: {
    choosedOptions: {
      type: Object,
      default() {
        return {
          selectedList: [],
          keyword: ""
        };
      }
    },
    esize: {
      type: Object,
      default() {
        return { height: "700px", width: "1000px" };
      }
    },
    drawAgain: {
      type: Number,
      default: 1
    }
  },
  mixins: [chartText],
  mounted() {},
  watch: {
    drawAgain: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.get_data();
        }
      }
    }
  },
  computed: {
    ctdata() {
      return Object.entries(CounterAmount(this.chartData, this.keyProp));
    }
  },
  methods: {
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/chart", {
          chartName: this.chartName,
          choosedOptions: this.choosedOptions,
          userToken: userToken
        })
        .then(response => ((this.chartData = response.data), this.drawIt()));
    },
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById(vm.chartName));
      // 绘制图表
      myChart.setOption({
        title: {
          text: vm.title,
          // subtext: vm.selectedListTextFormat(vm.choosedOptions.selectedList),
          left: "center",
          top: "6%",
          textStyle: {
            color: "#888"
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          right: '1%',
          bottom: '5%',
          textStyle: {
                                color: '#aaa'
                            }
        },
        series: [
          {
            name: "数量",
            type: "pie",
            roseType: 'angle',
            radius: ["20%", "50%"],
            center: ["50%", "50%"],
            data: vm.chartData,
            itemStyle: {
              opacity: 0.9
            }
          }
        ],
        grid: {
          x: "20%",
          y: "20%",
          x2: "20%",
          y2: "20%",
          borderWidth: 10
        },
        toolbox: {
          show: false,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: {
              show: true
            },
            dataView: {
              show: true,
              readOnly: false
            },
            restore: {
              show: true
            },
            saveAsImage: {
              show: true
            }
          }
        },
        label: {
          normal: {
            textStyle: {
              color: "rgba(255, 255, 255, 0.3)"
            }
          }
        },
        labelLine: {
          normal: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)"
            },
            smooth: 0.2,
            length: 10,
            length2: 20
          }
        }
      });
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });**/
      myChart.on("legendselectchanged", function(value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.keyProp
        });
      });
    }
  }
};
</script>
<style scoped>
.chart-wrapper {
  border-radius: 3px;
  margin: 0 auto;
}
</style>