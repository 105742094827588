<template>
  <div class="back-box" @click="toggleFullscreen" :style="backStyle">
    <div class="title">
      <!-- <h2>轨道交通运营安全评价系统</h2> -->
      <div class="img-title-wrapper">
        <img :src="titleUrl" class="logo" />
      </div>
    </div>
    <div class="section-wrapper">
      <div class="section left">
        <div class="panel-box">
          <div class="inner-title" style="height:4.5%;">
            <p>评估期间数据</p>
          </div>
          <div class="content-wrapper" style="height:95.5%;">
            <div class="charts-outer-wrapper" style="height:8%;">
              <div class="charts-wrapper">
                <div class="data-wrapper">
                  <div class="data-box">
                    <p class="data">{{pdata.accidentAmount}}</p>
                    <p class="label">历史险性事件总数量</p>
                    <p class="sub-label"></p>
                  </div>
                  <div class="data-box">
                    <p class="data">{{pdata.correctAccidentAmount}}</p>
                    <p class="label">待整改</p>
                    <p class="sub-label"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="charts-outer-wrapper" style="height:31%;">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="ios-warning" :size="18" />
                    <span class="split-tab"></span>
                    险性事件数量趋势情况
                  </p>
                </div>
                <year-trend
                  :choosedOptions="choosedOptionsA"
                  :drawAgain="update_count"
                  :esize="echartSizeR"
                ></year-trend>
              </div>
            </div>
            <div class="charts-outer-wrapper" style="height:31%;">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="md-stats" :size="18" />
                    <span class="split-tab"></span>
                    险性事件分析
                  </p>
                </div>

                <div class="charts-inner-wrapper" style="height:100%;">
                  <div class="harf-chart">
                    <continue-period
                      :choosedOptions="choosedOptionsA"
                      :drawAgain="update_count"
                      :esize="echartSizeR"
                    ></continue-period>
                  </div>
                  <div class="harf-chart">
                    <reason-point
                      :choosedOptions="choosedOptionsA"
                      :drawAgain="update_count"
                      :esize="echartSizeR"
                    ></reason-point>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="charts-outer-wrapper">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="logo-vimeo" :size="18" />
                    <span class="split-tab"></span>
                    各线路隐患排查情况
                  </p>
                </div>
                <div class="charts-inner-wrapper">
                  <div class="harf-chart">
                    <ld-line
                      :choosedOptions="choosedOptionsA"
                      :drawAgain="update_count"
                      :esize="echartSizeR"
                    ></ld-line>
                  </div>
                  <div class="harf-chart">
                    <ldcorrect-stat
                      :choosedOptions="choosedOptionsA"
                      :drawAgain="update_count"
                      :esize="echartSizeR"
                    ></ldcorrect-stat>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="charts-outer-wrapper" style="height:31%;">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="ios-timer" :size="18" />
                    <span class="split-tab"></span>
                    不正点列次情况
                  </p>
                </div>
                <chart-temp3
                  v-show="vs === 't'"
                  :chart_draw_again="update_count"
                  :totalData="totalData"
                  :major="major"
                ></chart-temp3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section middle">
        <div class="panel-box">
          <div class="inner-title" style="height:5%;">
            <p>安全评估风险点</p>
          </div>
          
          <div class="charts-outer-wrapper ttr" style="height:12%;">
            <transition name="component-fade" >
            <div class="charts-wrapper" v-if="sw" :key="currentYear">
              <div class="charts-title">
                <p class="time-label">{{currentYear}}</p>
              </div>
              <div class="data-wrapper">
                <div class="data-box" v-for="(value,key,index) in pdata[currentYear]" :key="index">
                  <p class="data">{{value}}</p>
                  <p class="label">{{key}}</p>
                  <p class="sub-label"></p>
                </div>
                <!-- <div class="data-box">
                  <p class="data">{{pdata[currentYear].correctRiskAmount}}</p>
                  <p class="label">整改中</p>
                  <p class="sub-label"></p>
                </div>
                <div class="data-box">
                  <p class="data">{{pdata[currentYear].normalRiskAmount}}</p>
                  <p class="label">常态化管理</p>
                  <p class="sub-label"></p>
                </div>
                <div class="data-box">
                  <p class="data">{{pdata[currentYear].completeRiskAmount}}</p>
                  <p class="label">已完成</p>
                  <p class="sub-label"></p>
                </div> -->
              </div>
            </div>
            </transition>
          </div>
          <div class="charts-outer-wrapper" style="height:30%;">
            <div class="charts-wrapper">
              <div class="charts-title">
                <p>
                  <Icon type="ios-aperture-outline" :size="18" />
                  <span class="split-tab"></span>
                  风险点分布情况
                </p>
              </div>
              <risk-spread
                      :choosedOptions="choosedOptions"
                      :drawAgain="update_count"
                      :esize="echartSize"
                    ></risk-spread>
            </div>
          </div>
          <div class="middle-inner-wrapper" style="height:50%;">
            <div class="middle-left" style="height:100%;">
              <div class="content-wrapper" style="height:100%;">
                <div class="charts-outer-wrapper" style="height:50%;">
                  <div class="charts-wrapper">
                    <div class="charts-title">
                      <p>
                        <Icon
                          type="ios-information-circle-outline"
                          :size="18"
                        />
                        <span class="split-tab"></span>
                        不同等级风险点占比
                      </p>
                    </div>
                    <degree-level
                      :choosedOptions="choosedOptions"
                      :drawAgain="update_count"
                      :esize="echartSize"
                    ></degree-level>
                  </div>
                </div>
                <div class="charts-outer-wrapper" style="height:50%;">
                  <div class="charts-wrapper">
                    <div class="charts-title">
                      <p>
                        <Icon type="ios-transgender" :size="18"/>
                        <span class="split-tab"></span>
                        不同整改要求风险点情况
                      </p>
                    </div>
                    <correct-demand
                      :choosedOptions="choosedOptions"
                      :drawAgain="update_count"
                      :esize="echartSize"
                    ></correct-demand>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="middle-right" style="height:100%;">
              <div class="content-wrapper" style="height:100%;">
                <div class="charts-outer-wrapper" style="height:50%;">
                  <div class="charts-wrapper">
                    <div class="charts-title">
                      <p>
                        <Icon type="ios-git-pull-request" :size="18" />
                        <span class="split-tab"></span>
                        不同类别风险点情况
                      </p>
                    </div>
                    <risk-sort
                      :choosedOptions="choosedOptions"
                      :drawAgain="update_count"
                      :esize="echartSize"
                    ></risk-sort>
                  </div>
                </div>
                <div class="charts-outer-wrapper" style="height:50%;">
                  <div class="charts-wrapper">
                    <div class="charts-title">
                      <p>
                        <Icon type="ios-train-outline" :size="18"/>
                        <span class="split-tab"></span>
                        不同专业风险点情况
                      </p>
                    </div>
                    <major-system
                      :choosedOptions="choosedOptions"
                      :drawAgain="update_count"
                      :esize="echartSize"
                    ></major-system>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div class="section right">
        <div class="panel-box">
          <div class="inner-title" style="height:4.5%;">
            <p>风险点整改情况</p>
          </div>
          <div class="content-wrapper" style="height:95.5%;">
            <div class="charts-outer-wrapper" style="height:8%;">
              <div class="charts-wrapper">
                <div class="data-wrapper">
                  <div class="data-box">
                  <p class="data">{{pdata.correctRiskAmount}}</p>
                  <p class="label">整改中</p>
                  <p class="sub-label"></p>
                </div>
                <div class="data-box">
                  <p class="data">{{pdata.normalRiskAmount}}</p>
                  <p class="label">常态化管理</p>
                  <p class="sub-label"></p>
                </div>
                <div class="data-box">
                  <p class="data">{{pdata.completeRiskAmount}}</p>
                  <p class="label">已完成</p>
                  <p class="sub-label"></p>
                </div>
                </div>
              </div>
            </div>
            <div class="charts-outer-wrapper" style="height:30%;">
                  <div class="charts-wrapper">
                    <div class="charts-title">
                      <p>
                        <Icon type="ios-transgender" :size="18"/>
                        <span class="split-tab"></span>
                        整改状态占比
                      </p>
                    </div>
                    <correct-stat
                      :choosedOptions="choosedOptionsA"
                      :drawAgain="update_count"
                      :esize="echartSize"
                    ></correct-stat>
                  </div>
                </div>
                <div class="charts-outer-wrapper">
            <div class="charts-wrapper">
              <div class="charts-title">
                <p>
                  <Icon type="ios-aperture-outline" :size="18" />
                  <span class="split-tab"></span>
                  整改中风险点详情
                </p>
              </div>
              <div class="list-data">
                <table style="width:100%;">
                  <tr v-for="(ele,i) in pdata.correctRiskList" :key="i">
                  <td style="width:15%;">{{ele.line_name}}</td>
                  <td>{{ele.risk_content}}</td>
                  <td style="width:15%;">{{ele.major_system}}</td>
                  <!-- <td>{{ele.major_system}}</td> -->
                  <!-- <td>{{ele.correct_stat}}</td> -->
                </tr>
                </table>
                <p class="more">
                  <a href="/conclusion/correct_l">更多</a>
                  </p>
                
              </div>
            </div>
          </div>
            <!-- <div class="charts-outer-wrapper">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="md-walk" :size="18" />
                    <span class="split-tab"></span>
                    各条线路客流量
                  </p>
                </div>
                <chart-passenger-flow
                  :chart_draw_again="update_count"
                  :totalData="totalData"
                ></chart-passenger-flow>
              </div>
            </div> -->
            <!-- <div class="charts-outer-wrapper">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="md-timer" :size="18" />
                    <span class="split-tab"></span>
                    各线路延误次数
                  </p>
                </div>
                <chart-delay
                  :chart_draw_again="update_count"
                  :totalData="totalData"
                ></chart-delay>
              </div>
            </div>
            <div class="charts-outer-wrapper">
              <div class="charts-wrapper">
                <div class="charts-title">
                  <p>
                    <Icon type="ios-timer" :size="18" />
                    <span class="split-tab"></span>
                    不正点列次情况
                  </p>
                </div>
                <chart-temp3
                  v-show="vs === 't'"
                  :chart_draw_again="update_count"
                  :totalData="totalData"
                  :major="major"
                ></chart-temp3>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import chartPassengerFlow from "@/components/AnalysisCharts/ChartPassengerFlow.vue";
// import chartDelay from "@/components/AnalysisCharts/ChartDelay.vue";
import chartTemp3 from "@/components/AnalysisCharts/ChartTemp3.vue";
import degreeLevel from "@/components/AnalysisCharts/DegreeLevel.vue";
import riskSort from "@/components/AnalysisCharts/RiskSort.vue";
import yearTrend from "@/components/AnalysisCharts/YearTrend.vue";
import reasonPoint from "@/components/AnalysisCharts/ReasonPoint.vue";
import continuePeriod from "@/components/AnalysisCharts/ContinuePeriod.vue";
// import ldcorrectStat from "@/components/AnalysisCharts/LDCorrectStat.vue";
// import ldLine from "@/components/AnalysisCharts/LDLine.vue";
import majorSystem from "@/components/AnalysisCharts/MajorSystem.vue";
import correctDemand from "@/components/AnalysisCharts/CorrectDemand.vue";
import riskSpread from "@/components/AnalysisCharts/RiskSpread.vue";
import RiskSpread from './RiskResult/Risk/Charts/RiskSpread.vue';
import correctStat from "@/components/AnalysisCharts/CorrectStat.vue";

let userToken = localStorage.getItem("current_user_token");
export default {
  name: "indexData",
  components: {
    // chartLineAmount,
    // chartTemp2,
    chartTemp3,
    // chartDelay,
    // chartClear,
    // chartOntime,
    // chartPassengerFlow,
    degreeLevel,
    riskSort,
    yearTrend,
    reasonPoint,
    continuePeriod,
    // ldcorrectStat,
    // ldLine,
    majorSystem,
    correctDemand,
    riskSpread,
    correctStat
  },
  data() {
    RiskSpread
    return {
      titleUrl: process.env.BASE_URL + "img/analysis_title.png",
      currentYear:"2019年",
      sw:true,
      screenHeight:document.documentElement.clientHeight,
      pdata:{
        accidentAmount:107,
        correctAccidentAmount:0,
        lineAmount:8,
        stationAmount:215,
        totalLength:358.235,
        correctRiskAmount:184,
        normalRiskAmount:35,
        completeRiskAmount:72,
        correctRiskList:[
          {line_name:'2号线',risk_content:"齿轮箱吊杆裂纹",degree_level:"较大的",correct_stat:"整改中",major_system:"车辆"},
          {line_name:'2号线',risk_content:"屏蔽门绝缘下降的风险",degree_level:"较大的",correct_stat:"整改中",major_system:"机电"},
          {line_name:'2号线',risk_content:"TC车个别车门无紧急解锁装置",degree_level:"一般的",correct_stat:"整改中",major_system:"车辆"},
          {line_name:'2号线',risk_content:"部分车站出入口无雨棚",degree_level:"一般的",correct_stat:"整改中",major_system:"运营组织"},
          {line_name:'3号线',risk_content:"屏蔽门绝缘下降的风险",degree_level:"较大的",correct_stat:"整改中",major_system:"机电"},
          {line_name:'3号线',risk_content:"地铁入口处无雨棚",degree_level:"一般的",correct_stat:"整改中",major_system:"安全管理"},
          {line_name:'2号线',risk_content:"齿轮箱吊杆裂纹",degree_level:"较大的",correct_stat:"整改中",major_system:"车辆"},
          {line_name:'2号线',risk_content:"屏蔽门绝缘下降的风险",degree_level:"较大的",correct_stat:"整改中",major_system:"机电"},
          {line_name:'2号线',risk_content:"TC车个别车门无紧急解锁装置",degree_level:"一般的",correct_stat:"整改中",major_system:"车辆"},
          {line_name:'2号线',risk_content:"部分车站出入口无雨棚",degree_level:"一般的",correct_stat:"整改中",major_system:"运营组织"},
          {line_name:'3号线',risk_content:"屏蔽门绝缘下降的风险",degree_level:"较大的",correct_stat:"整改中",major_system:"机电"},
          {line_name:'3号线',risk_content:"地铁入口处无雨棚",degree_level:"一般的",correct_stat:"整改中",major_system:"安全管理"},
          {line_name:'2号线',risk_content:"齿轮箱吊杆裂纹",degree_level:"较大的",correct_stat:"整改中",major_system:"车辆"},
          {line_name:'2号线',risk_content:"屏蔽门绝缘下降的风险",degree_level:"较大的",correct_stat:"整改中",major_system:"机电"},
          {line_name:'2号线',risk_content:"TC车个别车门无紧急解锁装置",degree_level:"一般的",correct_stat:"整改中",major_system:"车辆"},
          {line_name:'2号线',risk_content:"部分车站出入口无雨棚",degree_level:"一般的",correct_stat:"整改中",major_system:"运营组织"},
          {line_name:'3号线',risk_content:"屏蔽门绝缘下降的风险",degree_level:"较大的",correct_stat:"整改中",major_system:"机电"},

        ],
        '2019年':{
          
        '风险点总数量':117,
        '2号线':39,
        '3号线':36,
        '4号线':42,
        },
        '2020年':{
        '风险点总数量':174,
        '7号线':52,
        '10号线':45,
        '线网':77,
        },
        
      },
      totalData: [],
      choosedAmountData: "actualAmount",
      vs: "t",
      choosedProData: "",
      update_count: 1,
      echartSize: { height: "90%", width: "100%" },//205px
      echartSizeR: { height: "90%", width: "100%" },//233px
      choosedOptionsA:{
        selectedList:[],
        keyword:''
      },
      curIcon: process.env.BASE_URL + "img/icon7.png",
      major: {
        cheliang: "车辆",
        gongwu: "工务",
        gongdian: "供电",
        xinhao: "信号",
        pingbimen: "站台门",
        diaodu: "调度",
        chengwu: "乘务",
        zhanwu: "站务",
        chengke: "乘客",
        otherEquipment: "设备",
        other: "其他",
      },
    };
  },
  created() {
    this.get_data();
    this.get_correct_data();
  },
  mounted(){
    setInterval(this.changeYear,8000);
  },
  computed:{
    backStyle:function(){
      return "height:"+this.screenHeight+"px;";
    },
    choosedOptions: function(){ 
      return {
        selectedList: [
          {key_name:'evaluate_year',value:this.currentYear,}
        ],
        keyword: "",
      };
    }
  },
  methods: {
    changeYear(){
      if(this.currentYear=='2019年'){
        this.sw = false;
        this.currentYear = '2020年';
        setTimeout(()=>{this.sw = true;this.update_count++;},1000);
        
      }else{
        this.sw = false;
        this.currentYear = '2019年';
        setTimeout(()=>{this.sw = true;this.update_count++;},1000);
      }
      
    },
    toggleFullscreen() {
      let _this = this;      
      let el = document.documentElement;
      _this.openFullscreen(el);
      setTimeout(()=>{
        this.screenHeight = document.documentElement.clientHeight;
      },100);
        
      
      
      // if (document.fullscreenElement === null) {
      //   _this.openFullscreen(el);
      // } else {
      //   _this.quitFullscreen();
      // }
      
    },
    openFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        // IE11
        element.msRequestFullscreen();
      }
    },
    link: function (value) {
      location.href = value;
    },
    chooseTrainClass(value) {
      this.choosedAmountData = value;
    },
    chooseProClass(value) {
      if (this.choosedProData === value) {
        this.vs = "t";
        this.choosedProData = "";
      } else {
        this.vs = "o";
        this.choosedProData = value;
      }
    },
    get_data() {
      this.$axios
        .post(this.$url + "/ask_base_data", {
          userToken: userToken,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.totalData = response.data.res_record;
            this.update_count++;
          } else {
            console.log("fali");
          }
        });
    },
    get_correct_data() {
      this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData:'correctingData'
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.pdata.correctRiskList = response.data.res_record;
            this.pdata.correctRiskAmount = response.data.correctRiskAmount;
            this.pdata.normalRiskAmount = response.data.normalRiskAmount;
            this.pdata.completeRiskAmount = response.data.completeRiskAmount;
            this.pdata.accidentAmount = response.data.accidentAmount;
          } else {
            console.log("fali");
          }
        });
    },
  },
};
</script>
<style scoped>
.back-box {
  width: 100%;
  background-image: url("~@/assets/analysis_bg.jpg");
  color: #fff;
  font-family: "微软雅黑";
  overflow: hidden;
}

.img-title-wrapper {
  margin: 0 auto;
  width: 30%;
}

.img-title-wrapper img {
  display: block;
  width: 100%;
}
.title {
  height:10%;
  text-align: center;
  padding: 10px 0;
  box-sizing: border-box;
}
.section-wrapper {
  height:93%;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
}

.section {
  height:100%;
  overflow: hidden;
  padding: 0 5px;
}

.left,
.right {
  width: 25%;
  position: relative;
  bottom:5%;
}

.middle {
  width: 50%;
  height:95%;
}

.panel-box {
  height:100%;
  background-color: rgb(1, 6, 16, 0.8);
}

.inner-title {
  text-align: center;
  padding: 10px;
  font-weight: 600;
  font-size: 1.0rem;
  font-family: HeiTi;
  background-color: rgb(34, 88, 195, 0.1);
  color: #aaa;
}



.charts-outer-wrapper {
  border-top: 1px solid rgb(34, 88, 195, 0.5);
}

.charts-wrapper {
  height:100%;
  margin: 10px 10px;
}

.data-wrapper {
  display: flex;
  color: #aaa;
  justify-content: space-between;
}

.data-box {
  border-right: 1px solid rgb(34, 88, 195, 0.3);
  text-align: center;
  flex-grow: 1;
}

.data-box:last-child {
  border-right: none;
}

p.data {
  font-size: 1.4rem;
  text-align: center;
  line-height: 100%;
  padding-bottom: 8px;
  color: #ccc;
}

p.label {
  font-size: 0.8rem;
  text-align: center;
}

p.sub-label {
  font-size: 8px;
  text-align: center;
}

.charts-title {
  color: #80bfff;
  font-weight: 600;
  font-size: 0.6rem;
  text-indent: 5px;
  padding: 0.1rem 0;
}

span.split-tab {
  width: 5px;
}

.middle-inner-wrapper {
  display: flex;
}

.middle-left,
.middle-right {
  flex-grow: 1;
}

.middle-left {
  width: 45%;
  border-right: 1px solid rgb(34, 88, 195, 0.3);
}
.middle-right {
  width: 55%;
}

.middle-inner-wrapper .data-wrapper {
  padding: 20px 0 5px;
}

.list-data {
  color: #aaa;
  text-align: center;
  padding: 0 20px 20px;
}

.si p {
  position: relative;
  top: 10px;
}

.charts-inner-wrapper {
  display: flex;
}

.harf-chart {
  width: 50%;
}

.more{
  text-align: right;
  padding-right: px;
  color:#80bfff;
  cursor:pointer;
}

table{
  border-collapse: collapse;
  margin:20px 0;
}

td{
  border-right:1px solid rgb(34, 88, 195, 0.3);
  font-size: 0.2rem;
}

td:last-child{
  border-right:none;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: all 1s ease;
}
.component-fade-enter
/* .component-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(120%);
}

.component-fade-leave-to{
  transform: translateY(-120%);
}

.ttr{
  height:103px;
  overflow: hidden;;
}

.time-label{
  font-size:1.2rem;
}
</style>