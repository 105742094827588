<template>
  <div :id="chartName" class="chart-wrapper" :style="esize"></div>
</template>
<script>
import { chartText } from "@/libs/mixins/chartSubText.js";
function rnd(n, m) {
  var random = Math.floor(Math.random() * (m - n + 1) + n);
  return random;
}
let LineColor = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#91c7ae",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3",
];
export default {
  name: "yearTrend",
  data() {
    return {
      chartName: this.$options.name,
      title: "",
      chartData: [],
      chColor: "#c23531",
    };
  },
  props: {
    choosedOptions: {
      type: Object,
      default() {
        return {
          selectedList: [],
          keyword: "",
        };
      },
    },
    esize: {
      type: Object,
      default() {
        return { height: "700px", width: "1000px" };
      },
    },
    drawAgain: {
      type: Number,
      default: 1,
    },
  },
  mixins: [chartText],
  mounted() {
    let n = rnd(0, LineColor.length - 1);
    this.chColor = LineColor[n];
  },
  watch: {
    drawAgain: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0) {
          this.get_data();
        }
      },
    },
  },
  computed: {
    ctdata() {
      let temp = this.chartData;
      return temp;
    },
  },
  methods: {
    get_data() {
      let userToken = localStorage.getItem("current_user_token");
      this.$axios
        .post(this.$url + "/chart", {
          chartName: this.chartName,
          choosedOptions: this.choosedOptions,
          userToken: userToken,
        })
        .then((response) => ((this.chartData = response.data), this.drawIt()));
    },
    drawIt() {
      let vm = this;
      let myChart = this.$echarts.init(document.getElementById(vm.chartName));
      // 绘制图表
      let dataKey = [];
      let dataValue = [];
      for (let i of vm.ctdata) {
        dataKey.push(i.name);
        dataValue.push(i.value);
      }
      myChart.setOption({
        title: {
          text: vm.title,
          subtext: vm.selectedListTextFormat(vm.choosedOptions.selectedList),
          left: "center",
          top: "12%",
          textStyle: {
            color: "#888",
            verticalAlign: "top",
          },
        },
        tooltip: {
          trigger: "item",
        },
        grid: {
          x: "10%",
          y: "15%",
          x2: "5%",
          y2: "20%",
          borderWidth: 10,
        },

        toolbox: {
          show: false,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: {
              show: true,
            },
            dataView: {
              show: true,
              readOnly: false,
            },
            restore: {
              show: true,
            },
            saveAsImage: {
              show: true,
            },
          },
        },
        dataset: {
          source: vm.ctdata,
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#2258c3", //左边线的颜色
              opacity: 0.3,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#aaa",
            },
          },
          data: dataKey,
        },
        yAxis: {
          name: "次数",
          splitLine: {
            lineStyle: {
              color: "#4784e8",
              opacity: 0.1,
            },
          },
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#2258c3", //左边线的颜色
              opacity: 0.3,
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#aaa",
            },
          },
        },
        series: [
          {
            name: "数量",
            type: "line",
            data: dataValue,
            smooth: true,
            label: {
              show: true,
              rotate: 15,
            },
            itemStyle: {
              color: vm.chColor,
              opacity: 0.8,
              shadowBlur: 1,
              shadowColor: "#e6e6e6",
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
          },
        ],
        label: {
          normal: {
            textStyle: {
              color: "rgba(255, 255, 255, 0.3)",
            },
          },
        },
        labelLine: {
          normal: {
            lineStyle: {
              color: "rgba(255, 255, 255, 0.3)",
            },
            smooth: 0.2,
            length: 10,
            length2: 20,
          },
        },
      });
      /**myChart.on('click', function (value) {
                    vm.$emit("clickToChange", { 'value': [value.name], 'key': vm.chartKey });

                });**/
      myChart.on("legendselectchanged", function (value) {
        vm.$emit("clickToAppend", {
          value: value.selected,
          key: vm.chartName,
        });
      });
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  border-radius: 3px;
  margin: 0 auto;
}
</style>